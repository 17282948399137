var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%","display":"flex","flex-direction":"column"},attrs:{"fluid":""}},[_c('page-title',{attrs:{"title":"Roles","subtitle":"Browse and Manage your roles!","badge":this.total}}),_c('v-row',{staticClass:"mt-0 mb-1 justify-space-between"},[_c('v-col',[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.add}},[_c('i',{staticClass:"fal fa-plus mr-2"}),_vm._v("Add Role")])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"9","lg":"8"}},[_c('div',{staticClass:"d-flex flex-row align-center justify-end flex-wrap",staticStyle:{"gap":"0.5rem"}},[_c('export-excel'),_c('export-pdf'),_c('columns-visibility-control',{attrs:{"withColumnsOrder":true,"storageKey":_vm.storageKey},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}}),_c('v-text-field',{ref:"mainSearch",staticClass:"table-search-field",style:({
            'max-width':
              _vm.mainSearchInFocus || (_vm.options.search != '' && _vm.options.search != null)
                ? '200px'
                : '110px',
          }),attrs:{"label":"Search","dense":"","solo":"","clearable":"","hide-details":"","prepend-inner-icon":"far fa-search","loading":_vm.loadingStates.table},on:{"focus":function($event){_vm.mainSearchInFocus = true},"blur":function($event){_vm.mainSearchInFocus = false}},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}}),_c('refresh',{attrs:{"loading":_vm.loadingStates.table},on:{"refresh":function($event){return _vm.getDataDebounced()}}})],1)])],1),_c('v-data-table',{staticClass:"elevation-2 roles-table table-sticky-header-exclude-footer",staticStyle:{"overflow":"overlay","height":"100%","flex":"1 1 auto","display":"flex","flex-direction":"column"},attrs:{"dense":"","headers":_vm.tableHeader,"items":_vm.entities,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.options.itemsPerPage,"loading":_vm.loadingStates.table,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'far fa-arrow-to-left',
      lastIcon: 'far fa-arrow-to-right',
      prevIcon: 'far fa-angle-left',
      nextIcon: 'far fa-angle-right',
      itemsPerPageOptions: [15, 30, 50, 100],
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"blue","absolute":"","indeterminate":""}})]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{key:item.name,staticStyle:{"font-size":"11px","font-weight":"600"},attrs:{"label":"","x-small":"","color":"deep-purple","text-color":"white","ripple":false}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.isDefault",fn:function(ref){
    var item = ref.item;
return [(item.isDefault)?_c('v-chip',{staticStyle:{"font-size":"11px","font-weight":"600"},attrs:{"small":"","x-small":"","color":"amber","text-color":"gray darken-2"}},[_c('i',{staticClass:"far fa-check mr-2",staticStyle:{"line-height":"0"}}),_vm._v(" Default ")]):_c('span',[_c('i',{staticClass:"fal fa-times pl-2 text--disabled",staticStyle:{"line-height":"0"}})])]}},{key:"item.isSystem",fn:function(ref){
    var item = ref.item;
return [(item.isSystem)?_c('v-chip',{staticStyle:{"font-size":"11px","font-weight":"600"},attrs:{"small":"","x-small":"","color":"red","text-color":"white"}},[_c('i',{staticClass:"far fa-check mr-2",staticStyle:{"line-height":"0"}}),_vm._v(" System ")]):_c('span',[_c('i',{staticClass:"fal fa-times pl-2 text--disabled",staticStyle:{"line-height":"0"}})])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(!item.isSystem || _vm.currentUser.isSuperUser)?_c('v-menu',{attrs:{"dense":"","offset-x":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","elevation":"0"}},'v-btn',attrs,false),on),[_c('i',{staticClass:"far fa-ellipsis-v",staticStyle:{"font-size":"16px"}})])]}}],null,true)},[_c('v-list',{staticClass:"more-options-menu"},[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.update(item.id)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-pen")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Update")])],1)],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.del(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2 justify-center"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-trash-alt red--text")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Delete")])],1)],1)],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('img',{attrs:{"width":"500","src":"/img/art/fogg-no-connection-2.png"}}),_c('p',{staticClass:"font-weight-bold"},[_vm._v("No Data Available!")])]},proxy:true},{key:"loading",fn:function(){return [_c('video',{staticStyle:{"padding":"0.5rem"},attrs:{"width":"250","muted":"","loop":"","autoplay":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/img/art/astronaut-loves-music-4980476-4153140.mp4","type":"video/mp4"}})]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Searching the Cosmos...")])]},proxy:true}],null,true)}),_c('edit-role',{ref:"editRole",on:{"save":_vm.onRoleSaved,"delete":_vm.onRoleDeleted,"close":_vm.onRoleClosed}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }