export default [
  {
    text: "Actions",
    value: "actions",
    align: "left",
    width: "64px",
    sortable: false,
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Name",
    value: "name",
    order: 2,
    hidable: false,
    hidden: false
  },
  {
    text: "Default",
    value: "isDefault",
    order: 3,
    hidable: false,
    hidden: false
  },
  {
    text: "System",
    value: "isSystem",
    order: 4,
    hidable: false,
    hidden: false
  },
];
